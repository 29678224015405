
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.sidebar-footer {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 95%;
  text-align: left;
  color: #828282;
  font-size: 13px;
  margin: 10px 0;
  padding: 0 10px;
}
.admin-label {
  font-size: 13px;
  padding-left: 10px;
  /* //text-transform: capitalize; */
}
.save-icon-holder {
  float: right;
}
.save-icon {
  background: #e5aa13;
  padding: 5px;
  color: #210b0b;
}
.form-panel-default {
  border: 1px solid #dcdcdc;
  margin: 10px 0;
}
.form-panel-default .form-panel-heading {
  color: #4c4d5a;
  border-color: #dcdcdc;
  background: #f6f6f6;
  text-shadow: 0 -1px 0 rgba(50,50,50,0);
  position: relative;
  padding: 12px 15px;
}
.form-panel-heading h3 {
  font-weight: 500;
  display: inline-block;
}
.form-panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: inherit;
}
.form-panel-heading svg {
  height: 15px;
}

.form-panel-heading h3 svg {
  margin-right: 5px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.form-panel-body {
  padding: 15px;
  background: #fff;
}
.form-panel-body .MuiGrid-root {
  padding: 15px 0;
}
.column-label {
  width: 100%;
  /* // text-align: right; */
  margin-bottom: 0;
  padding-top: 9px;
  font-weight: 500;
  padding-right: 5px;
  color: #545454;
  font-size: 14px;
}
.save-btn-holder {
  margin: 10px 0;
  text-align: right;
}
.MuiGrid-root .react-calendar {
  border-radius: 15px;
  border: 1px solid #f0be40;
}
.time-line-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 500px;
  /* margin: 10px; */
}
.full-calender-container {
  margin: 30px 0;
  width: 100%;
}
.gantt_tooltip {
  padding:0 !important;
}
.ghant-tooltip-holder {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
.ghant-tooltip-holder p {
  padding: 0px;
  margin: 0;
  font-size: 12px;
}
.task-file-input .previews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.empty-chat{
  width: 100%;
  margin-top: 18%;
  text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
